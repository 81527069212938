
#integration {
  /* margin: 5rem auto 1rem; */
  /* width: 60%; */
  height: 100vh;
  max-width: 60%;
  margin: 0 auto 10rem;
  font-family: 'NotoSansGeorgian';


  }
section:has(.SectionNumber) {
  // background-color: #4d4d4d87;
}

  .integrationFlex {
    align-items: stretch;
    gap: 35px;
  }
    .grid-1 {
      display: grid;
      grid-column-gap: 33px;
      grid-row-gap: 33px;
      }
      .grid-2 {
        display: grid;
grid-column-gap: 35px;
grid-row-gap: 35px;
      }         
      
      .grid-item {
        color: rgb(130, 130, 130);
        padding: 0px 20px 20px 20px;
        border-radius: 30px;
        box-shadow: 12px 12px 24px rgb(15, 15, 15), -12px -12px 24px rgb(64 64 64);
        transition: transform 0.4s ease-in-out ;
        position: relative;
        z-index: 1;
        background-color: #292929;
      
      }
      .grid-item:hover::before {
        content: "";
        position: absolute;
        inset: 0;
        border-radius: inherit;
        padding: 1px;
        background: linear-gradient(90deg, rgb(253, 171, 155) 0%, rgb(252, 195, 246) 50%, rgb(218, 184, 252) 100%);
        -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
        -webkit-mask-composite: destination-out;
        mask-composite: exclude;
        pointer-events: none;

      }
      .grid-item:hover {
        
        transform: scale(1.05);
        z-index: 2;
      }
      .item1 {
        grid-area: 1 / 1 / 2 / 4;
      }
      .item1 h4, .item4 h4, .item2 h4, .item3 h4 {
        padding-left: 20px;

      }
      
      .item2 {
        grid-area: 2 / 1 / 3 / 3;
      }
      
      .item3 {
        grid-area: 2 / 3 / 3 / 4;
      }
      
      .item4 {
        grid-area: 1 / 1 / 2 / 3;
      }
      
      .item5 {
        grid-area: 2 / 1 / 3 / 3;
      }
      
      .item6 { grid-area: 3 / 1 / 4 / 2; 
        padding-bottom: 3rem;
      }
      .item7 {
        grid-area: 3 / 2 / 4 / 3;
        text-align: center;
        display: flex
    ;
        align-items: center;
        justify-content: center;
        padding-top: 20px;
    }


      .intShemosavlebi img {
        width: 35%;
        position: relative;
        left: 43%;
    }
      .intShemosavlebi ul, .intBanks ul {
        list-style-type: style type disc;
      }

      
      .intBanks img {
      width: 30%;
      position: relative;

  }

  .intDelivery {
      align-items: flex-end;
      gap: 3rem;
      padding: 0 2rem;
      flex-wrap: nowrap;

  }
  

.intDelivery .flexbox {
  justify-content: space-around ;
  padding: 0 1rem 1rem;
}

  .intDelivery .flexbox img{
    width: 80px;
    border-radius: 20px;
  }

  .intAccount .flexbox img {
      width: 40%;
  }
  .intAccount .flexbox {
    flex-wrap: wrap;
    
    justify-content: center;
    gap: 1rem;
    align-items: center;
  }


  .flex-item {
    display: flex
;
    justify-items: center;
    align-items: flex-end;
}


  .intHostell img {
    width: 66px;
}

#integration h4 {
font-size: 16px;
    font-family: 'NotoSansGeorgian';
    font-feature-settings: 'case' on;
    font-weight: 400;
}

#integration li {
  font-family: 'NotoSansGeorgian';
  font-weight: 200;
  font-feature-settings: 'case' on;
}

#integration h2 {
  font-family: 'bpgMrgvlovaniCaps';
   color: rgb(190, 190, 190);
   margin-bottom: 40px;
   font-weight: 200;
}

.forMore {
  text-align: end;

  position: absolute;
  right: 30px;
  bottom: 0;
  a {
      color: rgba(255, 255, 255, 0.661);
      text-underline-offset: 5px;
      font-size: 14px;
    font-family: 'NotoSansGeorgian';
    font-weight: 400;

    }
}
