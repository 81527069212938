#header {
    display: flex
;
    align-items: center;
    justify-content: space-evenly;
    background-color: #3a3a3a00;
    box-shadow: inset -1px 116px 63px -56px #000000;
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 1000;
    /* border-radius: 0 0 20px 20px; */
    height: 8rem;
    transition: top 1s ease, box-shadow 1s ease;
}
.hidden {
    top: -160px !important;
}
#header h1 {
    margin: 0.8rem 0;
}

#header img {
    width: 10rem;
}
#header p {
    font-family: 'bpg';
    cursor: pointer;
    font-feature-settings: 'case' on;
    margin: 0 !important;
    display: flex
;
    align-items: center;
}

#header p:hover {
    opacity: 0.8;

}

#header:hover {
    box-shadow: inset -1px 150px 63px -56px #000000;
}
#upRight {
    font-size:26px;
}
#listing ul {
    display: flex ;
    gap: 2rem;
}

#listing ul li {
    list-style-type: none;
    font-family: 'NotoSansGeorgian';
    font-weight: 300;
    font-feature-settings: 'case' on;
    font-size: 14px;

}

#listing ul li a {
    color: white;
    text-decoration: none;
}
#listing ul li a span {
    color: gray;
    margin-right: 1rem;

}

#listing ul li a:hover{
    opacity: 0.65;
    text-decoration: underline;
    text-underline-offset: 5px;
}

@media only screen and (max-width: 600px) {
    #header {
        display: none;
    }
}