#SyrveSection {
    background-image: url("../../assets/img/grid/finaltest1/slidebackground/slide\ background\ gradient\ with\ 292929.png");
    background-repeat: no-repeat;
    background-size: cover;
    height: 100vh;
    border-radius: 20px;
    position: relative;
    z-index: 10;
    /* min-height: 1000px */

}
#SyrveSection {
    h3{
        color:white
    }
}
#SyrveSectionInner {
    width: 100%;
    background-image: url("../../assets/img/grid/finaltest1/slidebackground/syrve\ pentagon\ gradient.svg");
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 30%;
    height: 100%;
        display: flex
    ;
        align-items: center;
        flex-direction: column;
        /* align-content: center; */
        justify-content: space-between;
}

#Syrve {
    display: flex
;
    align-items: center;
    flex-direction: column;
    position: relative;
    top: 3rem;
}

#Syrve h2 {

    text-align: center;
    font-size: 250px;
    color: white;
    margin: 0;
    font-family: "Nunito Sans", sans-serif;
    font-weight: 400;
}
#Syrve p {
    font-family: bpg;
    font-size: 16px;
    max-width: 500px;
    line-height: 2.3rem;
    font-family: 'bpg';
    position: relative;
    left: 34rem;
    top: -6rem;
    color: rgba(255, 255, 255, 0.825);

}

#Syrve p span {
    font-size:22px;
    display: block;
    font-weight: 900;
}

.SectionNumberMainDiv {
    align-self: flex-start;    
}

@media only screen and (max-width: 1700px) {
    #Syrve p {
        top: 0;
        left: 0;
        text-align: center;
    }
    #Syrve {
        top: 0;
    }
}