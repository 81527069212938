@font-face {
  font-family: LGV;
  src: url(./assets/fonts/LGV_Big_Noodle.ttf);
}

@font-face {
  font-family: NotoSansGeorgian;
  src: url(./assets/fonts/NotoSansGeorgian-VariableFont_wdth\,wght.ttf);
}

@font-face {
  font-family: bpg;
  src: url(./assets/fonts/bpg_banner_quadrosquare_caps.ttf);
}

@font-face {
  font-family: bpgMrgvlovaniCaps;
  src: url(./assets/fonts/bpg_mrgvlovani_caps_2010.ttf);
}


@font-face {
  font-family: modernistRegular;
  src: url(./assets/fonts/Sk-Modernist-Regular.otf);
}



@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,opsz,wght@0,6..12,200..1000;1,6..12,200..1000&display=swap');



html {
  scroll-behavior: smooth;
  color-scheme: dark;
  overflow-y: auto;

}

body {
  background-color: #292929;
  color: white;
  /* overflow-x:hidden; */
  /* cursor: url("data:image/svg+xml,%3Csvg%20version%3D%221.1%22%20id%3D%22pointer_cursor%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%20x%3D%220px%22%20y%3D%220px%22%20width%3D%2221px%22%20height%3D%2233px%22%20viewBox%3D%220%200%2021%2033%22%20enable-background%3D%22new%200%200%2021%2033%22%20xml%3Aspace%3D%22preserve%22%3E%3Cpolygon%20id%3D%22outer%22%20opacity%3D%220.5%22%20fill%3D%22%23FFFFFF%22%20points%3D%2220.2%2C20.2%2012.25%2C20.2%2016%2C29%209.667%2C31.75%205.75%2C22.5%200%2C28%200%2C0%20%22%2F%3E%3Cpolygon%20id%3D%22inner%22%20opacity%3D%220.5%22%20fill%3D%22%23231F20%22%20points%3D%226.373%2C19.482%201.75%2C23.904%201.75%2C4.225%2015.975%2C18.45%209.602%2C18.45%2013.708%2C28.087%2010.59%2C29.441%20%22%2F%3E%3C%2Fsvg%3E"), auto; */

}
.coming-soon-tag {
  display: none;
}
.flexbox {
  display: flex;   
}

.SectionNumber {
  padding:2rem 3rem;
  color: gray;
  font-family: bpg;
}

.SectionNumber span {
  opacity: 0.8;
}


::-webkit-scrollbar {
    width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3); 
    -webkit-border-radius: 10px;
    border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
    -webkit-border-radius: 10px;
    border-radius: 10px;
    background: rgb(190, 190, 190); 
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.5); 
}


.shiny-text {
  color: #b5b5b5a4; /* Adjust this color to change intensity/style */
  background: linear-gradient(
    120deg,
    rgba(255, 255, 255, 0) 40%,
    rgba(255, 255, 255, 0.8) 50%,
    rgba(255, 255, 255, 0) 60%
  );
  background-size: 200% 100%;
  -webkit-background-clip: text;
  background-clip: text;
  animation: shine 1s linear infinite;
  font-family: 'bpg';
  text-align: end;
  margin-right: 10rem;
  display: inline-block;
  right: -100px;
  bottom: 20px;
  position: absolute;
  z-index: 100;
}

@keyframes shine {
  0% {
    background-position: 100%;
  }
  100% {
    background-position: -100%;
  }
}

.shiny-text.disabled {
  animation: none;
}

.upDownAnimation {
  font-size: 20px;
  position: relative;
  animation: upDown 1.5s ease-in-out infinite;

}

 @keyframes upDown {
  0% {
      top: 0px;
  }
  50% {
      top: 10px;
  }
  100% {
      top: 0px;
  }
  
 }

 @media (-webkit-device-pixel-ratio: 1.25) {
  body {
    zoom: 0.8;
  }
}


@media (-webkit-device-pixel-ratio: 1.50) {
  body {
    zoom: 0.66;
  }
}

@media (-webkit-device-pixel-ratio: 1.75) {
  body {
    zoom: 0.57142857142;
  }
}


@media (-webkit-device-pixel-ratio: 2) {
  body {
    zoom: 0.5;
  }
}

@media only screen and (max-width: 600px) {
    html {
      overflow-x:hidden;
    }
    .body {
      display: none;
    }
    .coming-soon-tag {
      display: block;
    }
    .bg-black {
      background-color: #292929 !important;
    }

}