@keyframes expandOpacity {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

.popup {
    position: fixed;
    top:-10px;
    width: 100%;
    left: 50%;
    transform: translate(-50%, 0);
    background-color: rgba(0, 0, 0, 0.348);
    height: 100%;
    animation: expandOpacity 0.4s ease-in-out;
}
.popup form div {
    display: block;
    margin-bottom: 1rem;
}


.popup form {
    margin: auto;
    width: 30%;
    padding:3rem 5rem;
    /* background:linear-gradient(#7802fff4, #c623d8dc); */
    border-radius: 2rem;
    position: relative;
    top: 2rem;
    background-color: rgb(26 26 26 / 86%);
    box-shadow: 0px 0px 10px 0px #5e5e5e;


}

.popup form div input[type = "text" ],
.popup form div input[type = "email" ],
.popup form div input[type = "tel" ] {
    border: none;
    border-radius: 6px;
    height: 3rem;
    width: 24rem;
    margin-top: 1rem;
    box-shadow: -3px 8px 20px -2px black;
    background-color: rgba(255, 255, 255, 0.816);
    font-size: 24px;
    font-family: bpg;
    padding-left:1.5rem ;
    animation: expandWidth 2s ease-in-out forwards;
}

.close-popup {
    border: none;
    background: none;
    width: 100%;
    text-align: end;
    margin: 0;
}

.closeButton {
    font-size: 26px;
    cursor: pointer;
    color: white;
    transition: transform 0.5s ease-in-out, opacity 0.5s ease-in-out, color 0.5s ease-in-out;
    
}

.closeButton:hover {
    transform: scale(1.5) rotate(90deg) ;
    color: rgb(181, 172, 172);
}

input[type = "submit" ]{
    border: none;
    background-color: white;
    padding: 0.5rem 4rem;
    border-radius: 20px;
    font-weight: 900;
    font-family: bpg;
    cursor: pointer;
    transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
    text-align: center;
    width: 100%;
    margin-top: 1rem;
    color: black;
}

input[type = "submit" ]:hover{
    transform: scale(1.1);
    opacity: 0.8;
}