* {
    transition: transform 5s ease;
}


.bannerParent {
    height: 100vh;
    min-height: 1000px;
}
.bannerMainDiv {
    width: fit-content;
    margin: auto;
    position: relative;
    top: 100px;
    transform: translate(-0%, 0%);
}


#p_Restaurant {
    position: relative;
    z-index: -1;
    width: 1135px;
    display: inline;
}
#biznesis {
    width: 460px;
}
.bannerSVG {
    margin-top: 1rem;
    width: 287px;
}  

.flexboxs {
    display: flex;
}

#p_Automatization {
    width: 827px;
    position: relative;
    left: 1rem;
    top: 4rem;
}

#bannerImg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-20%, -50%);
    width: 808px;
    height: 700px;
    z-index: -1;
}
#Miniheader {
    text-align: center;
    font-family: 'lgv';
    font-size: 42px;
    font-weight: 200;
    color: rgb(210, 210, 210);
    letter-spacing: 1px;
    position: relative;
    top: 15%;
}


.buttonMainSect {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    gap: 2rem;
    transform: translate(0, 268%);
}


.buttonContactUs{
    font-size: 18px;
    padding: 0.6em 0.8em;
    border-radius: 10px;
    border: none;
    background-color: #292929;
    color: oklch(84.01% .009 308.34);
    cursor: pointer;
    font-family: notosansgeorgian;
    font-feature-settings: "case" on;
    font-weight: 500;
  }
  .buttonContactUs a {
    color: oklch(84.01% .009 308.34);
    text-decoration: none;
  }
  .containerButton {
    position: relative;
    padding: 1px;
    background: linear-gradient(90deg, rgb(253, 171, 155), rgb(252, 195, 246), rgb(218, 184, 252));;
    border-radius: 10px;
    transition: all 0.4s ease;
    width: fit-content;

  }

  .buttonWeContactYou{
    font-size: 18px;
    padding: 0.6em 0.8em;
    border-radius: 10px;
    border: none;
    background-color: #292929;
    cursor: pointer;
    font-family: notosansgeorgian;
    background: linear-gradient(90deg, rgb(253, 171, 155), rgb(252, 195, 246), rgb(218, 184, 252));
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent; /* Makes the text color transparent to show the gradient */
    font-family: notosansgeorgian;
    font-feature-settings: "case" on;
    font-weight: 400;
  }
  .buttonWeContactYou span {
    color: oklch(84.01% .009 308.34);
  }

  .containerWeContactYou {
    position: relative;
    padding: 1px;
    border:2px solid #413e46;
    border-radius: 10px;
    transition: all 0.4s ease;
    width: fit-content;


  }
  .containerButton::before {
    content: "";
    position: absolute;
    inset: 0;
    margin: auto;
    border-radius: 0.9em;
    z-index: -10;
    filter: blur(0);
    transition: filter 0.4s ease;
  }
  
  
  .containerButton:hover::before {
    background: linear-gradient(90deg, rgb(253, 171, 155), rgb(252, 195, 246), rgb(218, 184, 252));
    filter: blur(1.2em);
  }
  .containerButton:active::before {
    filter: blur(0.2em);
  }
  


  /* Responsive */


  @media only screen and (max-width: 600px) {
    
    .bannerMainDiv {
        /* transform: scale(0.5); */
        margin: 0;
        padding: 0;
    }
}

/* 
#forMore {

}

#forMore {
        animation: glow .5s infinite alternate;
    }
    
    @keyframes glow {
        to {
            text-shadow: 0 0 10px rgb(247, 0, 0);
        }
    }
     */

/* 




        

.flexbox {
    flex-wrap: nowrap;
}



 */