#pricingSectionMain {
  width: 100%;
  position: relative;
  min-height: 100vh;
}
.pricing-section {
    color: rgb(130, 130, 130);
    max-width: 60%;
    margin: 0 auto 10rem;
    font-family: 'NotoSansGeorgian';

  }
  .pricing-section h2 {
    font-family: 'bpgMrgvlovaniCaps';
    font-weight: 200;
    color: rgb(190, 190, 190);
  }
  .pricing-section h2 span {
    font-family: "Nunito Sans", sans-serif;
    font-size: 36px;
    color:rgb(130, 130, 130);
  }

.featureHeader {    
      display: flex;
      justify-content: flex-end;
      padding: 10px 20px;
      font-size: 16px;
      gap: 6rem;}
  
  .pricing-row {
    /* height: 5vh; */
    background: #292929;
    border: 2px solid transparent;
    border-radius: 20px;
    margin: 20px 0 34px 0;
    position: relative;
    box-shadow: 12px 12px 24px rgb(26, 26, 26), -12px -12px 24px rgb(64 64 64);
     }
  
  .active::before {
    transition: border 1s ease-in-out;
    content: "";
    position: absolute;
    inset: 0;
    border-radius: inherit;
    padding: 2px;
    background: linear-gradient(90deg, rgb(253, 171, 155) 0%, rgb(252, 195, 246) 50%, rgb(218, 184, 252) 100%);
    -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
    -webkit-mask-composite: destination-out;
    mask-composite: exclude;
    pointer-events: none;
  
}

.pricing-row:hover::before {
  content: "";
  position: absolute;
  inset: 0;
  border-radius: inherit;
  padding: 2px;
  background: linear-gradient(90deg, rgb(253, 171, 155) 0%, rgb(252, 195, 246) 50%, rgb(218, 184, 252) 100%);
  -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  -webkit-mask-composite: destination-out;
  mask-composite: exclude;
  pointer-events: none;
}


  
  .pricing-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 16px;
    font-family: 'NotoSansGeorgian';
    font-feature-settings: 'case' on;
    cursor: pointer;
    padding: 25px 20px;

  
  }
  
  .expandable-content {
    font-size: 14px;
    color: #aaa;
    /* clip-path: inset(0 round 100px); */

  }

  .expandable-content::-webkit-scrollbar-button {
    display: none; /* Hides the scrollbar arrows */
  }

  .arrow {
    font-size: 18px;
    color: #7f00ff;
    transition: transform 0.3s ease;
  }
  
  .section-item.active .arrow {
    transform: rotate(180deg);
}


.option {
    max-width: 282px;
    margin: 0;
    flex: 1 1;
    font-weight: 200;
    font-variant: all-petite-caps;
    font-size: 24px;
}

#firstSpan {
    max-width: 282px;
    margin: 0;
    flex: 1;
}

.pricing-list-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 10px 0;
  }
  
  .list-title {
    flex: 2 1;
    font-size: 14px;
    max-width: 550px;
}
  
  .plan-icons {
    display: flex;
    gap: 6rem;
    flex: 1 1;
    justify-content: flex-end;
    padding-right: 56px;
  }
  
  .plan-icon .icon {
    font-size: 36px;
    color: gray;
  }

  .enterpriseIcon 
    {
      margin-right: 0rem;
      left: 1rem;
      position: relative;
  }
/*   
  .plan-icon .icon.basic {
    color: #ff007f; 
  }
  
  .plan-icon .icon.pro {
    color: #7f00ff; 
  }
  
  .plan-icon .icon.enterprise {
    color: #00ff7f; 
  } */
  
