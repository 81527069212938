
  input[type="email"] {
    width: 250px;
    padding: 10px;
    border: none;
    border-radius: 8px 0 0 8px;
    outline: none;
  }
  
  .btnComingSoon {
    padding: 10px 20px;
    background-color: #1e90ff;
    color: white;
    border: none;
    border-radius: 0 8px 8px 0;
    cursor: pointer;
    transition: background-color 0.3s ease-in-out;
  }
  
  .btnComingSoon:hover {
    background-color: #007acc;
  }
  @keyframes upDown {
    0% {
      transform: translate(-0%, -5%);
    }
    50% {
      transform: translate(0%, 5%);

    }
    100% {
      transform: translate(-0%, -5%);
    }
  }
  .mt-10 {
    margin-top: 40px;
    font-size: 26px;
  }
  
  .h-screen {
    height: 100vh;
  }
  
  .flex {
    display: flex;
  }
  
  .flex-col {
    flex-direction: column;
  }
  
  .items-center {
    align-items: center;
    text-align: center;
  }
  
  .justify-center {
    justify-content: center;
  }
  
  .bg-black {
    background-color: black;
  }
  
  .text-white {
    color: white;
  }
  
  .text-6xl {
    font-size: 4rem;
    animation: upDown 3s infinite ease-in-out;
  }
  
  .font-bold {
    font-weight: bold;
  }
  
  .mb-6 {
    margin-bottom: 24px;
  }
  
  .text-lg {
    font-size: 1.125rem;
  }
  
  .mb-8 {
    margin-bottom: 32px;
  }
  