.FooterSection {
    width: 100%;
    height: 100vh;
    background-color: #31313149;
    a{
        color: white;
        text-decoration: none;
        font-family: bpg;
        font-weight: 500;
        display: block;
        margin-bottom: 16px;
        &:hover {
            text-decoration: underline;
            text-underline-offset: 10px;
            opacity: 0.4;
        }
    }
    ul {
        padding: 0;
        li {
            list-style-type: none;
        }
    }

    .footer-flexbox {
        display: flex;
        justify-content: space-between;
        padding: 0 2rem;
        h3 {
            text-transform: uppercase;
            color: rgb(141, 141, 141);
        }
        .footer-menu {

        }
        .footer-company  {
            img {
                width: 300px;
            }
        }
    }

    video  {
    min-width: 100%;
    min-height: 100vh;
    position: absolute;
    z-index: -1000;
    height: 100vh;
    object-fit: cover;
    opacity: 0.5;
    filter: blur(5px);
}
.footer-logo-flex {
    display: flex;
    align-items: center;
    justify-content: center;
    
     h2 {
        margin:3rem;
        font-size: 17.65vw;
        font-family: modernistRegular, sans-serif;
        line-height: 29vh;
        height: 100%;
        text-transform: uppercase;
        color: rgb(231, 232, 234);
        cursor: default;
        user-select: none; /* Standard */
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer */
    }


}

.rights {
    display: flex;
    justify-content: space-between;
    padding: 0 2rem;

}
.lineFooter {
    background: linear-gradient(to right,rgba(255, 0, 0, 0), rgb(231,232,234), #8000ff00); /* Pink to purple gradient */
    border-radius: 2px; /* Makes the edges rounded */
   height: 1px;
    margin: auto;
    border-radius: 2px; /* Makes the edges rounded */
    width: 100%;
}
}


.shiny-text-footer {
    color: #b5b5b5a4; /* Adjust this color to change intensity/style */
    background: linear-gradient(
      120deg,
      rgba(255, 255, 255, 0) 40%,
      rgba(255, 255, 255, 0.8) 50%,
      rgba(255, 255, 255, 0) 60%
    );
    background-size: 200% 100%;
    -webkit-background-clip: text;
    background-clip: text;
    animation: shine 0.1s linear infinite;
    font-family: 'bpg';
    text-align: end;
    margin-right: 10rem;
    bottom: 20px;
    z-index: 100;
}


@media only screen and (-webkit-device-pixel-ratio: 1.25) {
    .FooterSection, video {
        height:100vh * 1.25 !important;
        min-height: calc(100vh * 1.25) !important;
    }
}


@media only screen and (-webkit-device-pixel-ratio: 1.5) {
    .FooterSection, video {
        height:100vh * 1.5 !important;
        min-height: calc(100vh * 1.5) !important;
    
    }
}


@media only screen and (-webkit-device-pixel-ratio: 1.75) {
    .FooterSection, video {
        height:100vh * 1.75 !important;
        min-height: calc(100vh * 1.75) !important;
    
    }
}
